import React from 'react';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';
import Content from 'components/Content';

import styles from './RoleChangeModal.scss';

const RoleChangeModal = ({ handleUpdateRole, handleCreateNewWorkHistory, handleCancel }) => {
  return (
    <DialogueModal>
      <div className={styles.changeRoleModal}>
        <div className={styles.changeRoleTitle}>Update role description</div>
        <div className={styles.changeRoleContent}>
          <Content>
            Do you have a new role at your current company or are you just correcting this role?
          </Content>
          <Content>
            If you have a new role at the same company, you need to create a new job entry.
          </Content>
          <Content>
            If you are correcting your current role title, that&apos;s fine, but you&apos;ll lose
            any sales data that you have entered related to the current role, as sales data is
            connected to specific role types.
          </Content>
        </div>
        <div className={styles.changeRoleButtons}>
          <Button onClick={handleCancel} quaternary={true}>
            Cancel
          </Button>
          <Button onClick={handleUpdateRole} warningTertiary={true}>
            Correct this role
          </Button>
          <Button onClick={handleCreateNewWorkHistory} primary={true}>
            Create new job
          </Button>
        </div>
      </div>
    </DialogueModal>
  );
};

export default RoleChangeModal;
