const color1 = '#495057';
const color2 = '#6c757d';
const color3 = '#212529';

export const colorWest = color1;
export const colorSouthWest = color3;
export const colorMidWest = color2;
export const colorNorthEast = color3;
export const colorSouthEast = color1;

export const regions = {
  ['West']: {
    states: ['WA', 'ID', 'MT', 'WY', 'CO', 'UT', 'NV', 'CA', 'OR', 'AK', 'HI'],
    color: colorWest,
  },
  ['Southwest']: {
    states: ['AZ', 'NM', 'TX', 'OK'],
    color: colorSouthWest,
  },
  ['Midwest']: {
    states: ['ND', 'SD', 'NE', 'KS', 'MN', 'IA', 'MO', 'WI', 'IL', 'IN', 'MI', 'OH'],
    color: colorMidWest,
  },
  ['Northeast']: {
    states: ['PA', 'NY', 'DE', 'MD', 'CT', 'RI', 'MA', 'VT', 'NH', 'ME', 'NJ', 'DC'],
    color: colorNorthEast,
  },
  ['Southeast']: {
    states: ['AR', 'LA', 'MS', 'KY', 'TN', 'AL', 'GA', 'FL', 'SC', 'NC', 'VA', 'WV'],
    color: colorSouthEast,
  },
};
