import { regions } from 'components/UsMapView/states';

export default [
  {
    id: 'locwest',
    tracking_hash: {
      locations: ['West'],
    },
    name: 'West',
    default: false,
    type: 'region',
    queries: regions.West.states.map((state) => `filter[locations_meta.state][]=${state}`),
  },
  {
    id: 'locsouthwest',
    tracking_hash: {
      locations: ['Southwest'],
    },
    name: 'Southwest',
    default: false,
    type: 'region',
    queries: regions.Southwest.states.map((state) => `filter[locations_meta.state][]=${state}`),
  },
  {
    id: 'locmidwest',
    tracking_hash: {
      locations: ['Midwest'],
    },
    name: 'Midwest',
    default: false,
    type: 'region',
    queries: regions.Midwest.states.map((state) => `filter[locations_meta.state][]=${state}`),
  },
  {
    id: 'locsoutheast',
    tracking_hash: {
      locations: ['Southeast'],
    },
    name: 'Southeast',
    default: false,
    type: 'region',
    queries: regions.Southeast.states.map((state) => `filter[locations_meta.state][]=${state}`),
  },
  {
    id: 'locnortheast',
    tracking_hash: {
      locations: ['Northeast'],
    },
    name: 'Northeast',
    default: false,
    type: 'region',
    queries: regions.Northeast.states.map((state) => `filter[locations_meta.state][]=${state}`),
  },
  {
    id: 'locother',
    tracking_hash: {
      locations: ['Other'],
    },
    name: 'Other',
    default: false,
    type: 'region',
    queries: ['filter[locations_meta.state][]=Other'],
  },
];
