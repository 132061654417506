import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroller';
import store from 'store';

// import { createSavedSearch } from 'reduxStore/schema';

import { EmployeeAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { employerHomePageValues } from 'utils/constants';
import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';

import AutocompleteServer from 'connectors/AutocompleteServer';

import { loadingSelectorFactory } from 'reduxStore/selectorFactories';

import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Button from 'components/Button';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import SearchBar from './components/SearchBar';
import SearchResult from './components/SearchResult';
import { SideFiltersPane } from './components/SideFiltersPane';

import { promiseSearchOptions } from './promises';

import {
  flattenLocations,
  // composeQueryString,
  composeQueryStringEx,
  deconstructQueryStringOld,
  deconstructQueryStringEx,
  trackSearchViewEvent,
  // trackSaveSearchEvent,
  loadNewResults,
  loadMoreResults,
  handleScrollToTop,
  // getDefaultSearchOptions,
  validateFilters,
  buildSideFilters,
  getDefaultSideFiltersData,
} from './tools';

import locationsConfig from './locationsConfig';

import duck from './redux';

import styles from './CandidateSearch.scss';

const CandidateSearch = ({
  candidateSearchOptions: candidateSearchOptionsProps,
  isAdmin,
  isEmployee,
  employerId,
  employer,
  actions,
  actions: {
    saveSearchOptions,
    // patchResource,
    // resourceUpdate,
    resultsUpdate,
    showModal,
    removeCandidatesFromSearch,
    setEmulatedEmployer,
    resetEmulatedEmployer,
    bookmarksAddStarted,
    bookmarksRemoveStarted,
    resultsReset,
    resetSearchParams,
  },
  candidates: { byId: candidatesById = {}, allIds: candidatesAllIds = [] } = {},
  searchString,
  total,
  user: { currentProfile: { employer: { homepage } = {} } = {} } = {},
}) => {
  const _mounted = useRef(false);
  const defaultRoleParams = useRef();
  const queryString = useRef();
  const syncLocalStorage = useRef(false);
  const [candidateSearchOptions, setCandidateSearchOptions] = useState(candidateSearchOptionsProps);
  const [experience, setExperience] = useState([]);
  const [roleIndex, setRoleIndex] = useState();
  const [locations, setLocations] = useState([]);
  const [searchActivatedId, setSearchActivatedId] = useState('');
  const [sideFiltersData, setSideFiltersData] = useState(getDefaultSideFiltersData());
  const [sideFilters, setSideFilters] = useState([]);
  const [allSearchParams, setAllSearchParams] = useState([]);
  const searchResultsContainerRef = useRef();

  const isSearching = useSelector(loadingSelectorFactory('employerSearch', 'candidate'));

  const employerName = useMemo(() => employer?.name || '', [employer]);

  const role = useMemo(
    () => (candidateSearchOptions.role && candidateSearchOptions.role[roleIndex]) || {},
    [candidateSearchOptions.role, roleIndex]
  );

  const searchParamsEmpty = useMemo(() => {
    // Check if current params are the same as default params so
    // we know if we need to show reset option
    const paramLabels = allSearchParams.map((param) => param.label || param.name);

    const defaultKeys =
      Object.keys(candidateSearchOptions).filter((key) =>
        candidateSearchOptions[key].find((option) => option.default)
      ) || [];

    const defaultLabels = defaultKeys.map((key) => {
      const defaultOption = candidateSearchOptions[key].find((option) => option.default);

      return defaultOption ? defaultOption.name : null;
    });

    const noNullDefaultLabels = defaultLabels.filter((label) => label !== null);

    return (
      paramLabels.length === noNullDefaultLabels.length &&
      paramLabels.every((label) => noNullDefaultLabels.includes(label)) &&
      sideFilters.length === 0
    );
  }, [allSearchParams, candidateSearchOptions, sideFilters.length]);

  const excludedLinks = useMemo(
    () =>
      isEmployee && homepage && Array.isArray(employerHomePageValues[homepage]?.excluded_pages)
        ? employerHomePageValues[homepage].excluded_pages
        : [],
    [homepage, isEmployee]
  );

  const locationsFlat = useMemo(
    () => flattenLocations(candidateSearchOptions?.locations) || [],
    [candidateSearchOptions]
  );

  const clearSideFilters = useCallback(() => {
    setSideFiltersData((data) => {
      const { lat, lon, distance, place } = data;

      const newSideFiltersData = {
        ...getDefaultSideFiltersData(),
        lat,
        lon,
        distance,
        place,
      };

      setSideFilters(buildSideFilters(newSideFiltersData));

      return newSideFiltersData;
    });
  }, []);

  const clearSideFiltersLocation = useCallback(() => {
    setSideFiltersData((data) => {
      const newSideFiltersData = {
        ...data,
        lat: null,
        lon: null,
        distance: null,
        place: null,
      };

      setSideFilters(buildSideFilters(newSideFiltersData));

      return newSideFiltersData;
    });
  }, []);

  // load search options and load/parse query string from LS
  useEffect(() => {
    promiseSearchOptions()
      .then((candidateSearchOptions) => {
        // saveSearchOptions({ candidateSearchOptions: { ...candidateSearchOptions } });

        // override/add hardcoded locations config
        //
        candidateSearchOptions.locations = locationsConfig;

        if (candidateSearchOptions.experience) {
          delete candidateSearchOptions.experience;
        }

        if (!candidateSearchOptions || !Array.isArray(candidateSearchOptions.role)) {
          console.error('Invalid candidateSearchOptions', candidateSearchOptions);
          handleError(new Error('Invalid candidateSearchOptions'));
          return;
        }

        const defaultRoleIndex = candidateSearchOptions.role.findIndex((item) => item.default) ?? 0;

        setRoleIndex(defaultRoleIndex);
        defaultRoleParams.current = {
          filter: 'role',
          label: candidateSearchOptions.role[defaultRoleIndex].name,
          id: candidateSearchOptions.role[defaultRoleIndex].id,
          value: true,
          queries: candidateSearchOptions.role[defaultRoleIndex].queries,
          trackingHash: candidateSearchOptions.role[defaultRoleIndex].trackingHash,
        };

        let params;

        let savedFilters = store.get('saved_filters');

        if (!savedFilters) {
          // if no new saved filters then check if prev saved filters are available

          const querystring = store.get('querystring');
          if (querystring) {
            params = deconstructQueryStringOld(
              querystring,
              candidateSearchOptions,
              defaultRoleParams.current.id
            );
          }
        }

        if (!params) {
          params = deconstructQueryStringEx(
            savedFilters,
            candidateSearchOptions,
            candidateSearchOptionsProps,
            defaultRoleParams.current
          );
        }

        const {
          allSearchParams: unvalidatedAllSearchParams, // list of filters
          sideFilters: unvalidatedSideFilters,
          sideFiltersData: unvalidatedSideFiltersData,
          experience, // list of ids
          locations, // list of ids
          role: roleId,
        } = params;

        const {
          allSearchParams: validatedAllSearchParams,
          sideFilters: validatedSideFilters,
          sideFiltersData: validatedSideFiltersData,
        } = validateFilters(
          candidateSearchOptions,
          unvalidatedAllSearchParams,
          unvalidatedSideFilters,
          unvalidatedSideFiltersData
        );

        if (!savedFilters) {
          savedFilters = composeQueryStringEx({
            isAdmin,
            sideFilters: validatedSideFilters,
            allSearchParams: validatedAllSearchParams,
            employerId,
            place: validatedSideFiltersData.place,
          });
          store.set('saved_filters', savedFilters);
        }

        setAllSearchParams(validatedAllSearchParams);
        setSideFilters(validatedSideFilters);
        setSideFiltersData(validatedSideFiltersData);
        setExperience(experience);
        setLocations(locations);
        setRoleIndex(candidateSearchOptions?.role?.findIndex((item) => item.id === roleId));

        queryString.current = savedFilters;

        setCandidateSearchOptions(candidateSearchOptions);
      })
      .then(() => {
        syncLocalStorage.current = true;
      })
      .catch(handleError);
  }, [candidateSearchOptionsProps, employerId, isAdmin, saveSearchOptions]);

  // save query string to localStorage
  useEffect(() => {
    if (!_mounted.current || !syncLocalStorage.current) return;

    const savedFilters = composeQueryStringEx({
      isAdmin,
      sideFilters,
      allSearchParams,
      employerId,
      place: sideFiltersData.place,
    });
    store.set('saved_filters', savedFilters);

    queryString.current = savedFilters;
  }, [
    allSearchParams,
    candidateSearchOptions,
    employerId,
    isAdmin,
    sideFilters,
    sideFiltersData.place,
  ]);

  // load/update content
  useEffect(() => {
    if (!_mounted.current) return;

    loadNewResults({
      isAdmin,
      actions,
      sideFilters,
      allSearchParams,
      employerId,
      searchResultsContainerRef,
    });
  }, [actions, allSearchParams, candidateSearchOptions, employerId, isAdmin, sideFilters]);

  // mounted/unmounted indicator
  useEffect(() => {
    _mounted.current = true;

    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    return () => {
      resetSearchParams();
      resultsReset();
    };
  }, [resetSearchParams, resultsReset]);

  const handleRemove = (filter) => {
    if (filter === 'experience') {
      setExperience([]);
    }

    setAllSearchParams((allSearchParams) =>
      allSearchParams.filter((param) => param.filter !== filter)
    );
    setSearchActivatedId('');
  };

  // locations changes
  //
  const handleAddCheckboxPickerMoreFilter = (item) => {
    const { id, subcategories, optionType, title, label, trackingHash } = item;

    // only locations are supported at the moment
    if (title !== 'locations') return;

    // folder with no subcategories - no action
    if (optionType === 'folder' && (!Array.isArray(subcategories) || subcategories.length === 0)) {
      return;
    }

    let newFilter = [...locations];
    let newAllParams = [...allSearchParams];

    if (optionType === 'folder') {
      const allSelected = subcategories.every((sub) => newFilter.includes(sub.id));

      if (allSelected) {
        newFilter = newFilter.filter(
          (filterId) => subcategories.every((sub) => sub.id !== filterId) && filterId !== id
        );

        newAllParams = newAllParams
          .filter((filter) => filter.id !== id)
          .filter((filter) => subcategories.every((sub) => sub.id !== filter.id));
      } else {
        newFilter.push(id);
        subcategories.forEach(
          ({ queries: sQueries, label: sLabel, trackingHash: sTrackingHash, id: sId }) => {
            if (!newFilter.includes(sId)) {
              newFilter.push(sId);
            }
            if (!newAllParams.find((param) => param.id === sId)) {
              newAllParams.push({
                label: sLabel,
                filter: title,
                value: true,
                queries: sQueries,
                trackingHash: sTrackingHash,
                id: sId,
              });
            }
          }
        );
      }
    } else {
      if (newFilter.includes(id)) {
        newFilter = newFilter.filter((filter) => filter !== id);
        newAllParams = newAllParams.filter((filter) => `${filter.id}` !== id);
      } else {
        newFilter.push(id);
        newAllParams.push({
          label,
          filter: title,
          value: true,
          queries: locationsFlat.find((loc) => loc.id === id)?.queries,
          trackingHash,
          id,
        });
      }
    }

    setLocations(newFilter);
    setAllSearchParams(newAllParams);
    setSearchActivatedId('');

    clearSideFiltersLocation();
  };

  // role and experience changes
  //
  const handleFilterUpdate = (params) => {
    const { filter, label, trackingHash, id } = params;

    if (filter === 'role') {
      setRoleIndex(candidateSearchOptions?.role?.findIndex((item) => item.id === id) ?? 0);

      clearSideFilters();

      setExperience([]);

      // clean up experience if role changes
      setAllSearchParams((allSearchParams) => [
        ...allSearchParams.filter((param) => param.filter !== 'experience'),
      ]);
    } else if (filter === 'experience') {
      setExperience([id]);
    }

    setAllSearchParams((allSearchParams) => [
      ...allSearchParams.filter((param) => param.filter !== filter),
      {
        label,
        filter,
        value: true,
        queries: candidateSearchOptions[filter].find((filter) => filter.id === id)?.queries,
        trackingHash,
        id,
      },
    ]);

    setSearchActivatedId('');
  };

  const handleSideFilterChange = useCallback((name, value) => {
    setSideFiltersData((data) => {
      const newSideFiltersData = { ...data, [name]: value };

      setSideFilters(buildSideFilters(newSideFiltersData));

      return newSideFiltersData;
    });
  }, []);

  const handleSideFilterLatLonChange = useCallback((lat, lon) => {
    setSideFiltersData((data) => {
      const newSideFiltersData = { ...data, lat, lon };

      setSideFilters(buildSideFilters(newSideFiltersData));

      return newSideFiltersData;
    });
  }, []);

  // const handleSaveSearch = ({ title }) => {
  //   const resource = createSavedSearch();

  //   resourceUpdate({
  //     resource,
  //     id: resource.id,
  //     slice: 'employerSearch',
  //     type: 'saved_searches',
  //   });

  //   const querystring = composeQueryString({
  //     isAdmin,
  //     allSearchParams,
  //     employerId,
  //     moreFilters,
  //     sideFilters,
  //   });

  //   patchResource({
  //     slice: 'employerSearch',
  //     type: 'saved_searches',
  //     attributes: {
  //       title,
  //       querystring,
  //       savedFilters: queryString.current,
  //     },
  //     id: resource.id,
  //     requiredFields: ['title'],
  //     ignoreValidations: true,
  //     successCallback: ({ resource }) => setSearchActivatedId(resource.id),
  //   });

  //   if (!isAdmin) {
  //     trackSaveSearchEvent({
  //       saveSearchTitle: title,
  //       allSearchParams,
  //       moreFilters,
  //     });
  //   }
  // };

  // const handleLoadSavedSearch = ({ savedSearch }) => {
  //   const {
  //     id,
  //     attributes: { savedFilters },
  //   } = savedSearch;

  //   setSearchActivatedId(id);

  //   const searchProperties = deconstructQueryStringEx(
  //     savedFilters,
  //     candidateSearchOptions,
  //     candidateSearchOptionsProps,
  //     defaultRoleParams.current
  //   );

  //   const {
  //     allSearchParams,
  //     moreFilters,
  //     sideFilters,
  //     sideFiltersData,
  //     experience,
  //     locations,
  //     role: roleId,
  //   } = searchProperties;

  //   setAllSearchParams(allSearchParams);
  //   setMoreFilters(moreFilters);
  //   setSideFilters(sideFilters);
  //   setSideFiltersData(sideFiltersData);
  //   setExperience(experience);
  //   setLocations(locations);
  //   setRoleIndex(candidateSearchOptions?.role?.findIndex((item) => item.id === roleId));
  // };

  const handleClearHides = () => {
    resultsUpdate({
      entity: {
        byId: candidatesById,
        allIds: candidatesAllIds.filter((id) => !candidatesById[id].hide),
      },
      type: 'candidate',
    });
  };

  const handleOnContextMenu = (candidate) => {
    if (!isAdmin) {
      trackSearchViewEvent(
        {
          total,
          allSearchParams,
          sideFilters,
          searchActivatedId,
        },
        candidate
      );
    }
  };

  const openProfileModal = (candidate) => {
    if (!isAdmin) {
      trackSearchViewEvent(
        {
          total,
          allSearchParams,
          sideFilters,
          searchActivatedId,
        },
        candidate
      );
    }

    showModal({
      key: 'CandidateProfileModal',
      parent: 'CandidateSearchPage',
      route: `/candidates/${candidate.id}/profile/`,
      search: '?tp=f',
      loadMore: () =>
        loadMoreResults({
          isAdmin,
          actions,
          candidatesAllIds,
          employerId,
          total,
          allSearchParams,
          sideFilters,
        }),
      closeHook: removeCandidatesFromSearch,
    });
  };

  const handleClearSearch = () => {
    // const defaultOptions = getDefaultSearchOptions(candidateSearchOptions, allSearchParams);
    // const {
    //   experience,
    //   moreFilters,
    //   locations,
    //   role,
    //   allSearchParams: defaultAllSearchParams,
    // } = defaultOptions;

    const defaultRoleIndex = candidateSearchOptions?.role?.findIndex((item) => item.default) ?? 0;

    setExperience([]);
    setLocations([]);
    setRoleIndex(defaultRoleIndex);
    setSideFilters([]);
    setSideFiltersData(getDefaultSideFiltersData());
    setAllSearchParams([
      {
        ...candidateSearchOptions?.role?.[defaultRoleIndex],
        filter: 'role',
        value: true,
      },
    ]);
    setSearchActivatedId('');
    resetEmulatedEmployer();
  };

  const handleEmployerChange = ({ target: { value } }) => {
    setEmulatedEmployer({ employer: value });
  };

  const handleClearEmployer = () => {
    setSearchActivatedId('');
    resetEmulatedEmployer();

    handleScrollToTop(searchResultsContainerRef);
  };

  const handleAddBookmark = (candidateId) => {
    bookmarksAddStarted({ candidateId, viewedFromPage: 'featured' });
  };

  const handleRemoveBookmark = (args) => {
    bookmarksRemoveStarted({ ...args });
  };

  const results = candidatesAllIds
    .map((id) => candidatesById[id].attributes)
    .map((cand) => (
      <SearchResult
        key={cand.id}
        index={cand.indexPosition}
        isAdmin={isAdmin}
        candidate={cand}
        handleAddBookmark={handleAddBookmark}
        handleRemoveBookmark={handleRemoveBookmark}
        handleOnContextMenu={handleOnContextMenu}
        handleOnClick={(candidate) => {
          handleClearHides();
          openProfileModal(candidate);
        }}
        searchString={searchString}
      />
    ));

  const searchContent =
    results.length === 0 && !isSearching ? (
      <div className={styles.emptySearchResultsContainer}>No Results</div>
    ) : (
      <div className={styles.searchResultsContainer} ref={searchResultsContainerRef}>
        {isSearching ? <TableOverlay table="search" /> : null}
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            loadMoreResults({
              isAdmin,
              actions,
              candidatesAllIds,
              employerId,
              total,
              candidateSearchOptions,
              allSearchParams,
              sideFilters,
            })
          }
          hasMore={results.length > 0 && total > results.length && !isSearching}
          loader={isSearching ? <div key={0} /> : <InfiniteScrollLoader key={0} />}
          useWindow={false}
        >
          {results}
        </InfiniteScroll>
      </div>
    );

  return (
    <>
      <Helmet key="helmet" title="Featured Candidates" />
      <ReadyContainer key="readyContainer" className={styles.CandidateSearch}>
        <Sidebar
          className={styles.sidebarClasses}
          type="search"
          page="candidateSearch"
          excludedLinks={excludedLinks}
          sideFiltersPane={
            <SideFiltersPane
              roleId={role?.id}
              data={sideFiltersData}
              handleSideFilterChange={handleSideFilterChange}
              handleSideFilterLatLonChange={handleSideFilterLatLonChange}
            />
          }
        />
        <LayoutWithoutSidebar
          className={styles.layoutContainer}
          content={
            <div className={styles.searchMainContent}>
              <Block
                title={
                  <div className={styles.searchBarContainer}>
                    <SearchBar
                      handleAddCheckboxPickerMoreFilter={handleAddCheckboxPickerMoreFilter}
                      handleRemove={handleRemove}
                      handleFilterUpdate={handleFilterUpdate}
                      experience={experience}
                      role={[role.id]}
                      locations={locations}
                      candidateSearchOptions={candidateSearchOptions}
                      highlightLocations={false}
                      className={styles.basicFiltersContainer}
                    />
                    <div className={styles.buttonsContainer}>
                      {!searchParamsEmpty && (
                        <Button key={'resetButton'} quaternary={true} onClick={handleClearSearch}>
                          Reset
                        </Button>
                      )}
                    </div>
                  </div>
                }
                boxShadow={true}
                addWhiteBG={true}
                addFlex={true}
                addChildFlex={true}
                addBottomFade={true}
              >
                {isAdmin && (
                  <div className={styles.autocomplete}>
                    <AutocompleteServer
                      placeholder={'Select an employer to emulate'}
                      size={'full'}
                      name={'employerId'}
                      value={employerName}
                      handleInputChange={handleEmployerChange}
                      autocompleteType={'employers'}
                      field={'name'}
                      needReset={true}
                    />
                    {employerName && (
                      <div className={styles.employerResultsContainer}>
                        <div className={styles.employerResults}>
                          Showing candidates for <Bolder>{employerName}</Bolder>
                        </div>
                        <Button quaternary={true} onClick={handleClearEmployer}>
                          Clear
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {searchContent}
              </Block>
            </div>
          }
        />
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(CandidateSearch));
