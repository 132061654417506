import React, { Component } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';

import CharacterCounter from 'components/CharacterCounter';
import InputErrors from 'components/InputErrors';
import Label from 'components/Label';

import styles from './Input.scss';

class Input extends Component {
  constructor(props) {
    super(props);

    this.debouncePeriod = props.debounce || props.debounce === 0 ? props.debounce : 200;

    this.state = {
      showErrors: false,
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errors = {}, value } = nextProps;
    const { value: currValue } = this.props;

    if (value !== currValue) {
      this.setState({ value });
    }

    if (errors.isAvailable && errors.isAvailable[0] === false) {
      this.setState({ showErrors: true });
    }

    const hasErrors = errors
      ? Object.keys(errors).filter((rule) => errors[rule] && errors[rule][0] === false).length > 0
      : false;

    const { showErrorsImmediately } = this.props;

    if (showErrorsImmediately) {
      this.setState({ showErrors: hasErrors });
    } else {
      if (!hasErrors || (!value && hasErrors && currValue !== value)) {
        this.setState({ showErrors: hasErrors });
      }
    }
  }

  handleSelfOnBlur = () => {
    const { errors } = this.props;

    const hasErrors = errors
      ? Object.keys(errors).filter((rule) => errors[rule] && errors[rule][0] === false).length > 0
      : false;

    this.setState({ showErrors: hasErrors });
  };

  handleInputChange = debounce((event) => {
    let {
      target: { value },
    } = event;

    const {
      target: { name },
    } = event;

    const { min, max, handleInputChange, errors } = this.props;

    const hasErrors = errors
      ? Object.keys(errors).filter((rule) => errors[rule] && errors[rule][0] === false).length > 0
      : false;

    if (!hasErrors) {
      this.setState({ showErrors: hasErrors });
    }

    if (min || max) {
      if (min !== null && parseInt(value, 10) < parseInt(min, 10)) {
        value = min;
      }
      if (max !== null && parseInt(value, 10) > parseInt(max, 10)) {
        value = max;
      }
      if (value === null || value === '' || value === undefined) {
        value = '';
      }

      handleInputChange({
        ...event,
        target: {
          ...event.target,
          value,
          name,
        },
      });
    } else {
      handleInputChange(event);
    }
  }, this.debouncePeriod);

  render() {
    const {
      autoCapitalize,
      characterLimit,
      errors,
      name,
      label,
      placeholder,
      titleInput,
      required,
      type,
      // handleInputChange,
      handleInputKeyPress,
      handleInputKeyDown,
      handleOnBlur,
      size,
      rows,
      fontSize,
      fontWeight,
      disabled,
      ultraDisabled,
      className,
      autoFocus,
      highlight,
      moneyInput,
      percentInput,
      oteInputOne,
      oteInputTwo,
      salaryInputOne,
      salaryInputTwo,
      isSearch,
      min,
      max,
      pattern,
      autoComplete,
      flexLabel,
      centered,
      smallMargin,
      secondaryType,
      noPaddingTop,
    } = this.props;

    const { showErrors, value } = this.state;

    const divClasses = classnames(styles.Input, {
      [styles.flexColumn]: titleInput,
      [styles.inputCentered]: centered,
    });

    const containerMainClass = classnames({
      [className]: Boolean(className),
      [styles.InputContainer]: !className,
      [styles.SearchContainer]: isSearch,
      [styles.noPaddingTop]: noPaddingTop,
    });

    const containerClasses = classnames(containerMainClass, {
      [styles.numInput]: type === 'number' || min || max,
    });

    const errorsClasses = classnames({
      [styles.inputErrors]: !titleInput,
      [styles.inputOteErrorsFirst]: oteInputOne,
      [styles.inputOteErrorsSecond]: oteInputTwo,
      [styles.inputSalaryErrorsFirst]: salaryInputOne,
      [styles.inputSalaryErrorsSecond]: salaryInputTwo,
    });

    const inputClasses = classnames(styles.inputStyle, {
      [styles.characterCounterGap]: characterLimit,
      [styles.inputStyleSearch]: isSearch,
      [styles.titleInput]: titleInput,
      [styles.inputSmall]: size === 'small',
      [styles.inputPhone]: size === 'phone',
      [styles.inputMedium]: size === 'medium',
      [styles.inputLarge]: size === 'large',
      [styles.inputExtraLarge]: size === 'xlarge',
      [styles.inputExtraExtraLarge]: size === 'xxlarge',
      [styles.inputFull]: size === 'full',
      [styles.inputNumber]: (type === 'number' || min || max) && secondaryType !== 'days',
      [styles.inputPercent]: percentInput,
      [styles.inputDays]: secondaryType === 'days',
      [styles.inputHugeFont]: fontSize === 'huge',
      [styles.inputBold]: fontWeight === 'bold',
      [styles.textArea]: type === 'textarea',
      [styles.ultraDisabledInput]: ultraDisabled,
      [styles.hasError]: showErrors,
      [styles.hasErrorTitle]: titleInput && showErrors,
      [styles.moneyInput]: moneyInput,
      [styles.focus]: highlight,
    });

    const inputProps =
      type === 'textarea'
        ? {
            name,
            type,
            value,
            onChange: (event) => {
              event.persist();
              this.setState({ value: event.target.value }, this.handleInputChange(event));
            },
            onKeyPress: handleInputKeyPress,
            onBlur: (event) => {
              !!handleOnBlur && handleOnBlur(event);
              return this.handleSelfOnBlur(event);
            },
            placeholder,
            className: inputClasses,
            rows,
            disabled,
            autoFocus,
            min,
            max,
            pattern,
            autoComplete,
          }
        : {
            autoCapitalize,
            name,
            type: moneyInput ? 'tel' : type,
            value,
            onChange: (event) => {
              event.persist();
              this.setState({ value: event.target.value }, this.handleInputChange(event));
            },
            onKeyPress: handleInputKeyPress,
            onKeyDown: handleInputKeyDown,
            onBlur: (event) => {
              !!handleOnBlur && handleOnBlur(event);
              return this.handleSelfOnBlur(event);
            },
            placeholder,
            className: inputClasses,
            rows,
            disabled,
            autoFocus,
            mask: value && value.length && type === 'tel' ? '(999) 999-9999' : null,
            maskChar: type === 'tel' ? '' : null,
            min,
            max,
            pattern,
            autoComplete,
          };

    if (characterLimit) {
      inputProps.maxLength = characterLimit;
    }

    if (type === 'number') {
      inputProps.step = '0.01';
    }

    if (name === 'email') {
      inputProps.type = 'email';
    }

    const input =
      type === 'textarea' ? <textarea {...inputProps} /> : <InputMask {...inputProps} />;

    const labelClasses = classnames({
      [styles.ultraDisabledLabel]: ultraDisabled,
      [styles.titleLabel]: titleInput,
      [styles.flexLabel]: flexLabel,
    });

    const labelProps = {
      disabled,
      required,
      className: labelClasses,
      htmlFor: name,
    };

    const characterLimitContent = characterLimit ? (
      <CharacterCounter
        className={styles.characterCounter}
        charactersToCount={value}
        numCharacters={characterLimit}
      />
    ) : null;

    const labelContent = label ? <Label {...labelProps}>{label}</Label> : null;

    const errorsContent = showErrors ? (
      <InputErrors className={errorsClasses} errors={errors} />
    ) : (
      <div className={smallMargin ? styles.smallFake : styles.fakeErrors} />
    );

    const labelContainerClasses = classnames(styles.regularLabel, {
      [styles.centeredLabel]: centered,
    });

    const labelContainerContent =
      label || showErrors ? (
        <div className={labelContainerClasses}>
          {labelContent}
          {errorsContent}
        </div>
      ) : null;

    const titleInputContent = titleInput ? (
      <div className={containerClasses}>
        <div className={divClasses}>
          <div className={labelContainerClasses}>
            {labelContent}
            {errorsContent}
          </div>
          {input}
        </div>
      </div>
    ) : null;

    const moneySymbol = moneyInput ? '$' : null;
    const percentSymbol = percentInput ? '%' : null;
    const symbol = moneySymbol;

    const secondaryTypeContent =
      secondaryType || percentSymbol ? (
        <div className={containerClasses}>
          <div className={divClasses}>
            {labelContainerContent}
            <div
              className={secondaryType === 'days' ? styles.daysPlaceholder : styles.typePlaceholder}
            >
              {input}
              <i>{secondaryType || percentSymbol}</i>
            </div>
          </div>
        </div>
      ) : null;

    const regularInputContent = symbol ? (
      <div className={containerClasses}>
        <div className={divClasses}>
          {labelContainerContent}
          <div className={styles.currencyPlaceholder}>
            <i>{symbol}</i>
            {input}
          </div>
        </div>
      </div>
    ) : (
      <div className={containerClasses}>
        <div className={divClasses}>
          {labelContainerContent}
          {input}
          {characterLimitContent}
        </div>
      </div>
    );

    return secondaryTypeContent || titleInputContent || regularInputContent;
  }
}

export default Input;
